<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ title }}</h4>
                </div>
                <div class="card-body">
                    <contract-type-form :contract-type="contractType">
                        <template #buttons="{save}">
                            <callback-button
                                class="ms-2"
                                :method="save"
                                :callback="back"
                            ></callback-button>
                        </template>
                    </contract-type-form>
                </div>
            </div>
        </div>
    </div>
    <!-- end row -->
</template>

<script>

import {mapGetters} from "vuex";
import CallbackButton from "../../../components/elements/callback-button.vue";
import ContractTypeForm from "../../../components/forms/contract-type-form.vue";

export default {
    components: {ContractTypeForm, CallbackButton},

    computed: {
        ...mapGetters({
            contractType: 'contractType/item'
        }),

        title: function () {
            return this.contractType.id ? this.$tc('projects::contract_types.contract_type', 2).ucFirst() + ' / ' + (this.contractType.translation_key ? this.$t(this.contractType.translation_key).ucFirst() :this.contractType.name) : this.$t('base.create_new_item', {item: this.$tc('projects::contract_types.contract_type', 1)}).ucFirst()
        },

        link: function () {
            return {path: this.$t('routes.' + '/master-data/contract-types')}
        }
    },

    methods: {
        back: function () {
            this.$router.push(this.link)
        },
    },

    watch: {
        title: function (value) {
            this.$store.dispatch('app/title', {key: value})
        },
    },

    mounted() {
        if (this.$route.params.id) {
            this.$store.dispatch('contractType/show', {id: this.$route.params.id})
        }
    },

    unmounted() {
        this.$store.dispatch('contractType/clearItem')
        this.$store.dispatch('app/title', '')
    }

}
</script>

<style scoped>

</style>
